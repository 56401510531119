.progress-container {
    height: 8px;
    border-radius: 2px;
    background-color: #d8d8d8;    
    position: relative;
}

.primary-progress {
    height: 8px;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    will-change: transform;
    border-radius: 2px;
    background-color: #417505;
}

.primary-progress-multi {
    /* -webkit-transform-origin: left center;
    transform-origin: left center; */
    /* -webkit-transform: scaleX(0);
    transform: scaleX(0);
    will-change: transform; */
    border-radius: 2px;
    position: absolute;
}


.multicolor-progress-container {
    background-color: #d8d8d8;
    height: 40px;
}

.multicolor-progress-container .multicolor-progress-bars .multicolor-progress-bar {
    float: left;
    height: 40px;
}

/* 
display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr)); */