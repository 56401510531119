
.comment-box {
    box-sizing: border-box;
    margin: 10px 0px;
    padding: 0px 16px 0px 8px;
    position: relative;
    /* min-height: 36px; */
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.18);
    background-color: rgba(41, 97, 253, 0.02);
}

.activity-feed-extra {
    border: solid 1px #979797; 
    height: 24px; 
    margin-left: 10px; 
    margin-bottom: 4px;
    width: 0; 
}


.vert-line {
    border: solid 1px #979797;
    height: 30px;
    width: 0px;
    margin-left: 10px;
}

.flipped-right-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 0 0;
    border-color: #2961fd transparent transparent transparent;
}

.button-imposter {
    color: var(--primary-button-color);
    cursor: pointer;
    font-size: 14px;
}

.comment-container {
    background-color: #2961fd;
    margin: 4px 0px 0px 0px;
    /* max-width: 320px; */
    border-radius: 4px 4px 4px 0px;
    white-space: pre-line;
}

.activity-comment {
    font-size: 16px;
    text-align: left;
    color: #ffffff;
    padding: 12px;
}

.activity-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.6px;
    text-align: left;
    color: #4a4a4a;
}

.activity-user-info {
    margin: 10px;
}

.activity-user-name {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.54);
}

.activity-user-time {
    font-size: 12px;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}

.history-box {
    height: 60px;
}

.history-box-icon-container {
    width: 56px;
}

.history-icon {
    width: 24px;
    height: 24px;
}

.history-dialog-title {
    height: 30px;
    font-size: 16px; 
    font-weight: 500; 
    opacity: 0.87; 
    margin-left: 10px; 
    margin-top: 10px; 
}

.history-dialog-container {
    height: calc(100% - 56px);
    margin: 0; 
    overflow-y: auto; 
    padding: 10px; 
}

.changed-history-container {
    white-space: pre-line;
    background-color: rgba(41, 97, 253, 0.15);
    border: 4px;
    font-size: 16px;
    opacity: 0.65;
    padding: 10px;
    margin: 10px;
}


.history-paper-dialog {
    min-height: 400px;
    height: 400px;
    max-height: 500px;
    min-width: 400px;
    max-width: 500px;
    margin: 0px;
    padding: 0px;
}

@media (max-width: 640px) {
    .history-paper-dialog {
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0px;
        padding: 0px;
    }

}
