.MuiBadge-dot {
  padding: 0;
  margin-right: 4px;
  margin-top: 4px;
  z-index: auto;
  min-width: 10px;
  width: 10px !important;
  height: 10px !important;
  opacity: .99;
  background: #ff4181;
  border-radius: 100%;
}

.MuiDataGrid-root {
  padding: 0;
}

/* Don't put damn blue outline around focus. */
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-colCell:focus {
  outline: none !important;
  outline-width: 0 !important;
}

/* No un-sort icon. */
.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon,
.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-iconButtonContainer {
  opacity: 0.0;
}

/* Sort icons. */
.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon {
  width: 1.75em;
  height: 1.5em;
  outline: none !important;
  fill: rgb(0,0,0,0.47);
  background-color: transparent;
}

/* Header settings. */
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  color: rgba(0,0,0,0.77);
  font-weight: 400 !important;
  padding-right: 8px !important;
  /* max-height: 40px !important; */
  min-height: 40px !important;
  height: 40px;
  line-height: 42px;
  font-family: 'Roboto', 'Arial', serif !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding-left: 8px !important;
}

/* Dont show the sort menu. It's stupid. */
.MuiDataGrid-root .MuiDataGrid-menuIcon {
  display: none !important;
}

/* Show 3 dots when text is long. */
.MuiDataGrid-cell {
  display: inline-block !important;
  text-overflow: ellipsis !important;
  margin: auto !important;
  padding-top: 14px !important;
  padding-bottom: 9px !important;
  min-height: 48px !important;
  max-height: 48px !important;
  font-size: 16px !important;
  color: #444444 !important;
}

/* More header settings. */
.MuiDataGrid-columnHeaders {
  max-height: 40px !important;
  min-height: 40px !important;
  height: 40px;
  line-height: 42px !important;
  padding: 0 !important;
  margin: 0;
  background-color: #F5F5F5;
  font-size: 16px;
  text-align: left;
  width:100%;
  color: rgb(68,68,68);
}

.MuiDataGrid-columnHeader {
  font-size: 16px !important;
  height: 40px;
  line-height: 40px;
  color: #444444 !important;
  font-weight: 500 !important;
  border-bottom: 0 !important;
  padding: 0 !important;
  outline: none !important;
  border-right: 1px solid rgb(224,224,224);
}

.MuiDataGrid-columnHeader:not(:last-of-type) {
  border-right: 1px solid rgb(219,219,219);
}

.MuiDataGrid-columnsContainer {
  background: #f5f5f5 !important;
}

.MuiDataGrid-iconSeparator {
  color: transparent !important;
}

.MuiPaper-root {
  box-shadow: unset !important;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.MuiPopover-paper {
  border: 1px solid rgb(224, 224, 224) !important;
}

#filterApplyButton, #filterClearButton {
  text-transform: uppercase !important;
}

#filterApplyButton:disabled, #filterClearButton:disabled {
  color: rgba(255,255,255,0.5) !important;
}

/* Dont highlight buttons on hover. It's stupid. */
#filterApplyButton:hover, #filterClearButton:hover {
  background-color: transparent;
}

/* Dont highlight checkbox on hover. It's stupid. */
.MuiCheckbox-root:hover {
  background-color: transparent !important;
}

/* Dont highlight sort icons on hover. It's stupid. */
.MuiIconButton-sizeSmall:hover {
  background-color: transparent !important;
}

.MuiDataGrid-virtualScroller {
  margin-top: 40px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 12px !important;
}

.MuiAccordion-root-Accordian-root.Mui-expanded {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 12px !important;
}

.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

div#bottombar .header-container {
  height: 68px !important;

}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.Mui-focused {
  border-color: transparent !important;
}

.MuiOutlinedIput-root:hover {
  border-color: transparent !important;

}

.MuiFormLabel-root, .MuiInputLabel-root {
  -webkit-transform: unset !important;
  transform: unset !important;
  font-size: 12px !important;
  color: rgba(0,0,0,0.6) !important;
}

.MuiInputBase-root, .MuiInput-root {
  margin-top: auto;
  margin-bottom: auto;
}

.MuiDataGrid-toolbarContainer {
  color: white;
  font-weight: 400 !important;
  font-family: 'Roboto', 'Arial', serif !important;
  position: absolute;
  top: -96px;
  left: calc(100%  - 100px);
  z-index: 999999;

}

.MuiDataGrid-paper {
  border-radius: 0 !important;
  border:  1px solid rgb(224, 224, 224) !important;
}

.MuiButton-sizeSmall {
  color: white !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  font-family: 'Roboto', 'Arial', serif !important;
}

div[role="tooltip"] {
  z-index: 9999999;
  top: -140px !important;
  margin-left: calc(100% - 667px) !important;
  border-radius: 0 !important;
  border: 1px solid #2961Fd;
}

.MuiDataGrid-filterFormOperatorInput {
  display: none !important;
}

.MuiDataGrid-filterFormValueInput {
  margin-left: 10px !important;
}

.MuiDataGrid-filterFormColumnInput {
  padding-right: 10px;
}

.MuiDataGrid-panelFooter {
  display: none !important;
}

.MuiDataGrid-filterFormDeleteIcon {
  display: none !important;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.tab-selected>span:first-child {
  width: unset;
  background: transparent !important;
}


.MuiTab-root.Mui-selected {
  color: #1976d2 !important;
  background-color: white;
  border-radius: 8px;
  min-width: 158px;
  height: 30px;
  min-height:30px;
  max-height:30px;
  margin-left: 10px;
  margin-top:auto;
  margin-bottom:auto;
}

