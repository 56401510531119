.charts-container {
    display: grid;
    grid-gap: 20px;
    /* grid-template-columns: 1fr repeat(auto-fit, minmax(340px, 1fr)); */
    /* grid-template-columns: 50% repeat(auto-fit, minmax(340px, 1fr)); */
    /* grid-template-columns: 50% 20% 20%; */
    /* grid-template-areas: 
                "a a d"
                "b c e"; */
    grid-template-columns: 60% 39%;
}

.state-prefiscal-year-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 40% 59%;

}

.chart-container-1 {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
}

.chart-container-3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));;
    grid-gap: 20px;
}


.chart-container-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-gap: 20px;
}

.pie-chart-container {
    background-color: #f8f8f8;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    height: 100%;
    min-width: 0;
    padding: 10px 0;
    position: relative;
}

.narratives-created-container {
    height: 108px;
}

.county-preaward-progress-container {
    height: 140px;
}

.grant-package-document-container {
    height: 372px;
}

.grant-document {
    font-size: 16px;
    opacity: 0.87;
    margin-bottom: 8px;
}

.number-of-narratives {
    font-size: 24px;
    font-weight: 500;
}

.county-charts-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 60% 39%;
}

.county-charts-container-col-1 {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

}

.county-charts-container-col-2 {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 120px 306px auto;
}

/* 
.line-chart {
    position: relative;
    grid-area: a;
}

.progress-bar-chart {
    position: relative;
    grid-area: b;
}

.stacked-bar-chart {
    position: relative;
    grid-area: c;
}

.pie-chart {
    position: relative;
    grid-area: d;
}

.doughnut-chart {
    position: relative;
    grid-area: e;
} */

/* .inline-grid {
    display: inline-grid;
} */

.donut-middle-text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 36px;
    font-weight: 300;    
    height: 40px; 
    left: 0; 
    position: absolute; 
    top: 50%; 
    text-align: center; 
    width: 100%; 
}

canvas {
    width: 100%;
    height: 100%;
}

.quarter-title-container {
    width: 28px;
    height: 28px;
    border-radius: 2px;
    border: solid 2px #2961fd;    
    color: #2961fd;
    font-weight: bold;
    font-size: 14px;;
}

.quarter-time-indicator-container {
    padding: 16px;
    font-size: 24px;
}

.quarter-time-remaining {
    margin-left: 16px;
    font-weight: 500;
}

.bsir-personnel-container {
    padding: 10px;
}

.bsir-personnel-amount {
    font-size: 24px;
}

.bsir-other-container {
    padding: 10px;
}

.bsir-other-amount {
    font-size: 24px;
}


.quarter-remaining {
    font-weight: normal;
}

@media (max-width: 1024px) {

    .charts-container {
        grid-template-columns: unset;
    }

    .state-prefiscal-year-container {
        /* grid */
        grid-template-columns: unset;
    }
    .county-charts-container {
        grid-template-columns: unset;
    }

    .charts-container > :last-child {
        margin-bottom: 20px;
    }

    .inline-grid {
        display: unset;
    }
}

