.s-container {
    display: inline-block;
    color:rgba(255,255,255,0.87);
    padding-left:7px;
    padding-right:8px;
    text-align:center;
    border-radius:2px;
    font-size: 14px;
    height: 20px;
    line-height: 1.5;
    opacity: 1 !important;
}

.s-green-1 {
    background-color: var(--status-green-1);
}

.s-green-2 {
    background-color: var(--status-green-2);
}

.s-green-3 {
    background-color: var(--status-green-3);
}

.s-grey-1 {
    background-color: var(--status-grey-1);
}

.s-grey-2 {
    background-color: var(--status-grey-2);
}

/* .s-yellow-1 {} */

.s-yellow-2 {
    background-color: var(--status-yellow-2);
}

/* .s-orange-1 {} */

.s-orange-2 {
    background-color: #c45d09;
}

/* .s-blue-1 {} */

.s-blue-2 {
    background-color: #4b97f2;
}

.s-blue-3 {
    background-color: #145db3;
}

/* .s-red-1 {} */

.s-red-2 {
    background-color: #990013;
}

/* .s-purple-1 {} */

.s-purple-2 {
    background-color: #854bf2;
}

.s-black-2 {
    background-color: #4a4a4a;
}

